.zipCity {
	display: flex;
	justify-content: space-between;
	column-gap: 1em;
}

.zipCity > * {
	flex: 1 0 auto;
}

.zipCity > :first-child {
	flex: 0 0 20%;
}
