*,
html {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	height: 100%;
}

body {
	font-family: "Open Sans", sans-serif;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.5em;
	color: #535557;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
	height: 100%;
	padding: 90px 0 0 0;
	position: relative;
	background-color: white;
}

span.link,
a {
	color: #d8011e;
	text-decoration: none;
	font-weight: 600;
}

span.link:hover,
a:hover {
	text-decoration: none;
	cursor: pointer;
}

h1 {
	font-size: 1.75em;
	font-weight: 600;
	margin-bottom: 1em;
	line-height: 1.3em;
}

#home {
	margin-top: -2rem;
}

#home div.success {
	margin-top: 1em;
}

#home h1.red {
	color: #d8011e;
	font-weight: 600;
	margin-bottom: 0;
	margin-top: 1rem;
}

#home h1 {
	font-weight: 400;
}

#home h2 {
	font-size: 1.5em;
	color: #d8011e;
	font-weight: 600;
	margin-bottom: 1rem;
	margin-top: 3rem;
}

#home h2.black {
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
	color: #535557;
	font-weight: 400;
}

#home li {
	padding-left: 1.3em;
	position: relative;
	padding-bottom: 0.5em;
}

#home li:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0.35em;
	width: 0.75em;
	height: 0.75em;
	background-color: #d8011e;
}

#home div.twoCols {
	margin-top: 0.5em;
	margin-bottom: 1rem;
}

#home div.twoCols::after {
	content: "";
	clear: both;
	display: block;
}

#home div.twoCols > div {
	float: left;
}

#home div.twoCols > div:first-child {
	width: 51%;
}

#home div.twoCols > div:last-child {
	width: 49%;
}

#home div.indent img {
	margin: 1rem 0;
}

h3 {
	font-size: 1.2em;
	font-weight: 600;
}

h4 {
	font-size: 1em;
	font-weight: 600;
}

textarea,
input {
	width: 100%;
	font-family: "Open Sans", sans-serif;
	font-weight: 600;
	font-size: 1em;
	color: #535557;
	padding: 0.5em;
	line-height: 1em;
	vertical-align: middle;
	border: 1px solid #aaa;
	border-radius: 5px;
}

input[type="checkbox"],
input[type="radio"] {
	width: auto;
}

textarea {
	line-height: 1.3em;
	resize: none;
}

::-webkit-input-placeholder {
	color: #ccc;
}

:-ms-input-placeholder {
	color: #ccc;
}

::placeholder {
	color: #ccc;
}

input:disabled {
	color: #666;
}

button,
input[type="submit"] {
	font-size: 1em;
	font-weight: 600;
	background-color: #d8011e;
	border: none;
	color: white;
	padding: 0 32px;
	line-height: 40px;
	text-decoration: none;
	cursor: pointer;
	border-radius: 5px;
	outline: none;
}

button {
	padding: 0 16px;
}

button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

div.buttonRow {
	margin-top: 1em;
}

div.button {
	margin-top: 1em;
}

div.button::after,
div.buttonRow::after {
	content: "";
	clear: both;
	display: block;
}

div.buttonRow button {
	float: left;
	margin-right: 1em;
}

div.buttonRow button.right {
	float: right;
	margin-right: 0;
}

span.required,
.required > input,
.required select,
.required label.radio {
	background-color: #306ab21a;
}

.required label.radio {
	padding: 2px 4px;
	border-radius: 5px;
}

.error > input,
.error select {
	background-color: white;
	border-color: #d8011e;
}

.error > span {
	color: #d8011e;
	font-weight: 600;
}

span.required {
	display: inline-block;
	border-radius: 5px;
	padding: 8px;
	border: 1px solid #aaa;
	font-weight: 600;
}

#topBar {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	height: 60px;
	background-color: white;
	z-index: 1;
	border-top: 5px solid #d8011e;
	border-bottom: 1px solid #ccc;
}

#topBar > div {
	margin: 0 auto;
	max-width: 800px;
	position: relative;
}

#topBar > div::after {
	content: "";
	clear: both;
	display: block;
}

svg.aerztefonLogo {
	margin-top: 16px;
	width: 220px;
	float: left;
}

#menu {
	position: absolute;
	height: 55px;
	right: 0;
	top: 0;
	display: flex;
	border-right: 1px solid #ccc;
}

#menu div.icon {
	border-left: 1px solid #ccc;
	width: 55px;
	height: 100%;
	cursor: pointer;
	position: relative;
}

#menu div.icon.selected::after {
	content: "";
	position: absolute;
	left: 24px;
	top: -4px;
	width: 8px;
	height: 8px;
	background-color: #d8011e;
	transform: rotateZ(45deg);
}

#menu div.icon svg {
	margin: 12px 0 0 12px;
	width: 31px;
	height: 31px;
}

#menu div.icon svg .color {
	fill: #d8011e;
}

#menu div.icon svg .color2 {
	fill: none;
}

#menu div.icon:hover {
	background-color: #d8011e;
}
#menu div.icon:hover > svg .color {
	fill: white;
}

#menu div.strokes {
	padding: 17px 14px;
	transition: padding 0.3s ease-in-out;
}

#menu div.strokes:hover,
#menu div.strokes.open {
	background-color: #d8011e;
	border-left-color: #d8011e;
}

#menu div.strokes > div {
	height: 3px;
	background-color: #d8011e;
	border-radius: 1px;
	transform: rotateZ(0);
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

#menu div.strokes > div:nth-child(2) {
	margin: 5px 0;
}

#menu div.strokes.open {
	padding: 17px 10px 17px 18px;
}

#menu div.strokes:hover > div,
#menu div.strokes.open > div {
	background-color: white;
}

#menu div.strokes > div:first-child {
	transform-origin: left top;
}

#menu div.strokes > div:last-child {
	transform-origin: left bottom;
}

#menu div.strokes.open > div:first-child {
	transform: rotateZ(40deg);
}

#menu div.strokes.open > div:last-child {
	transform: rotateZ(-40deg);
}

#menu div.strokes.open > div:nth-child(2) {
	opacity: 0;
}

#menu div.menuCover {
	position: fixed;
	left: 0;
	top: 60px;
	right: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.7);
}

#menu div.menu {
	position: absolute;
	right: -1px;
	top: 55px;
	border: 1px solid #ccc;
	border-top: none;
	font-size: 1.2em;
	min-width: 400px;
	border-radius: 0 0 5px 5px;
}

#menu div.menu > div {
	max-width: 800px;
	margin: 0 auto;
	background-color: rgba(255, 255, 255, 0.8);
	padding-top: 2rem;
	padding-bottom: 2rem;
	border-radius: 0 0 5px 5px;
}

#menu div.menu address {
	font-style: normal;
	font-size: 0.9em;
	padding: 3rem 55px 0 55px;
}

#menu li {
	line-height: 3em;
	padding: 0 55px;
}

#menu ul a,
#menu ul span {
	color: #535557;
	font-weight: 600;
	text-transform: uppercase;
	display: block;
	border-bottom: 1px solid #ccc;
}

#menu ul a:hover,
#menu ul span:hover {
	color: #d8011e;
	cursor: pointer;
	text-decoration: none;
}

#menu .active {
	color: #d8011e;
}

footer {
	position: fixed;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: #e2e1de;
	font-size: 0.88em;
	font-weight: 600;
	line-height: 2.5em;
	color: #535557;
}

footer > div {
	max-width: 800px;
	margin: 0 auto;
}

strong {
	font-weight: 600;
}

/* main {
	position: relative;
}

main > div {
	position: absolute;
	left: inherit;
	top: inherit;
	width: 100%;
	height: 100%;
}

main > div > div {
	max-width: 800px;
	padding-bottom: 4em;
	margin: 0 auto;
} */

main {
	max-width: 800px;
	padding-bottom: 4em;
	margin: 0 auto;
}

main img {
	width: 100%;
	display: block;
}

input.error {
	background-color: #f99;
	border-color: #c00;
}

input.error::placeholder {
	color: #f99;
}

button.right {
	float: right;
	margin-left: 1em;
}

div.select {
	flex-grow: 1;
	flex-shrink: 3;
	position: relative;
	vertical-align: bottom;
}

div.select::before {
	content: "▾";
	font-size: 2em;
	line-height: 1em;
	position: absolute;
	right: 8px;
	top: 0.1em;
	color: #444;
	pointer-events: none;
}

select {
	font-family: "Open Sans", sans-serif;
	font-weight: 600;
	font-size: 1em;
	border-radius: 5px;
}

div.select > select {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	border: 1px solid #999;
	border-radius: 8px;
	padding: 0.5em 2.5em 0.5em 0.5em;
	background-color: white;
	color: #444;
	outline: none;
	width: 100%;
}

select::-ms-expand {
	display: none;
}

select:focus::-ms-value {
	background-color: transparent;
}

label.radio {
	display: inline-block;
	cursor: pointer;
	margin-right: 1em;
}

fieldset > label.radio {
	padding-top: 0em;
}

label.radio input {
	margin-left: 1px;
	margin-right: 4px;
}

fieldset {
	border: 1px solid #ccc;
	padding: 1em;
	margin-top: 2em;
	border-radius: 0.5em;
}

legend {
	font-size: 0.9em;
	font-weight: 600;
	padding: 0 4px;
}

input.number {
	width: 5em;
}

label.birthdate input {
	text-align: right;
}

input.largeNumber {
	width: 10em;
}

div.spinner {
	position: fixed;
	z-index: 100;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
	padding: 2em 0;
}

div.window > div {
	width: 400px;
	margin: 0 auto;
	background-color: white;
	border-radius: 1em;
	padding: 1em;
}

.sk-circle {
	margin: 100px auto;
	width: 40px;
	height: 40px;
	position: relative;
}
.sk-circle .sk-child {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}
.sk-circle .sk-child::before {
	content: "";
	display: block;
	margin: 0 auto;
	width: 15%;
	height: 15%;
	background-color: white;
	border-radius: 100%;
	-webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
	animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
	-webkit-transform: rotate(30deg);
	-ms-transform: rotate(30deg);
	transform: rotate(30deg);
}
.sk-circle .sk-circle3 {
	-webkit-transform: rotate(60deg);
	-ms-transform: rotate(60deg);
	transform: rotate(60deg);
}
.sk-circle .sk-circle4 {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}
.sk-circle .sk-circle5 {
	-webkit-transform: rotate(120deg);
	-ms-transform: rotate(120deg);
	transform: rotate(120deg);
}
.sk-circle .sk-circle6 {
	-webkit-transform: rotate(150deg);
	-ms-transform: rotate(150deg);
	transform: rotate(150deg);
}
.sk-circle .sk-circle7 {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}
.sk-circle .sk-circle8 {
	-webkit-transform: rotate(210deg);
	-ms-transform: rotate(210deg);
	transform: rotate(210deg);
}
.sk-circle .sk-circle9 {
	-webkit-transform: rotate(240deg);
	-ms-transform: rotate(240deg);
	transform: rotate(240deg);
}
.sk-circle .sk-circle10 {
	-webkit-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	transform: rotate(270deg);
}
.sk-circle .sk-circle11 {
	-webkit-transform: rotate(300deg);
	-ms-transform: rotate(300deg);
	transform: rotate(300deg);
}
.sk-circle .sk-circle12 {
	-webkit-transform: rotate(330deg);
	-ms-transform: rotate(330deg);
	transform: rotate(330deg);
}
.sk-circle .sk-circle2::before {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}
.sk-circle .sk-circle3::before {
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}
.sk-circle .sk-circle4::before {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}
.sk-circle .sk-circle5::before {
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}
.sk-circle .sk-circle6::before {
	-webkit-animation-delay: -0.7s;
	animation-delay: -0.7s;
}
.sk-circle .sk-circle7::before {
	-webkit-animation-delay: -0.6s;
	animation-delay: -0.6s;
}
.sk-circle .sk-circle8::before {
	-webkit-animation-delay: -0.5s;
	animation-delay: -0.5s;
}
.sk-circle .sk-circle9::before {
	-webkit-animation-delay: -0.4s;
	animation-delay: -0.4s;
}
.sk-circle .sk-circle10::before {
	-webkit-animation-delay: -0.3s;
	animation-delay: -0.3s;
}
.sk-circle .sk-circle11::before {
	-webkit-animation-delay: -0.2s;
	animation-delay: -0.2s;
}
.sk-circle .sk-circle12::before {
	-webkit-animation-delay: -0.1s;
	animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes sk-circleBounceDelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

div.error,
div.success,
div.tempError > div,
div.tempSuccess > div {
	font-weight: 600;
	background-color: #090;
	color: white;
	padding: 0.5em 1em;
	line-height: 1.4em;
	margin-bottom: 1em;
	border-radius: 5px;
}

div.tempError > div,
div.tempSuccess > div {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

div.tempError,
div.tempSuccess {
	position: fixed;
	top: -0;
	transform: translateY(-100%);
	left: 50%;
	width: 300px;
	margin-left: -150px;
	text-align: center;
	z-index: 10;
	opacity: 0;
	transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

div.tempSuccess.show,
div.tempError.show {
	transform: translateY(0);
	opacity: 0.95;
}

div.tempError > div,
div.error {
	background-color: #d8011e;
}

@keyframes ldio-dyw8t9yacb-o {
	0% {
		opacity: 1;
		transform: translate(0 0);
	}
	49.99% {
		opacity: 1;
		transform: translate(44px, 0);
	}
	50% {
		opacity: 0;
		transform: translate(44px, 0);
	}
	100% {
		opacity: 0;
		transform: translate(0, 0);
	}
}
@keyframes ldio-dyw8t9yacb {
	0% {
		transform: translate(0, 0);
	}
	50% {
		transform: translate(44px, 0);
	}
	100% {
		transform: translate(0, 0);
	}
}
.ldio-dyw8t9yacb div {
	position: absolute;
	width: 44px;
	height: 44px;
	border-radius: 50%;
	top: 28px;
	left: 6px;
}
.ldio-dyw8t9yacb div:nth-child(1) {
	background: #d8011e;
	animation: ldio-dyw8t9yacb 7.142857142857142s linear infinite;
	animation-delay: -3.571428571428571s;
}
.ldio-dyw8t9yacb div:nth-child(2) {
	background: #fff;
	animation: ldio-dyw8t9yacb 7.142857142857142s linear infinite;
	animation-delay: 0s;
}
.ldio-dyw8t9yacb div:nth-child(3) {
	background: #d8011e;
	animation: ldio-dyw8t9yacb-o 7.142857142857142s linear infinite;
	animation-delay: -3.571428571428571s;
}
.loadingio-spinner-dual-ball-o20bp2k35w {
	width: 32px;
	height: 32px;
	display: inline-block;
	overflow: hidden;
	vertical-align: middle;
}
.ldio-dyw8t9yacb {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(0.32);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}
.ldio-dyw8t9yacb div {
	box-sizing: content-box;
}

ul {
	list-style: none;
}

@media (max-width: 832px) {
	div.indent {
		padding: 0 1rem;
	}

	body {
		font-size: 14px;
	}

	footer {
		font-size: 1em;
	}

	footer > div,
	#topBar > div {
		padding-left: 1rem;
	}
}

@media (max-width: 800px) {
	#menu {
		border-right: none;
		right: 0;
	}
}

@media (max-width: 480px) {
	body {
		font-size: 13px;
		padding-top: 60px;
	}

	svg.aerztefonLogo {
		width: 180px;
		margin-top: 11px;
	}

	#topBar {
		height: 45px;
		border-top: 4px solid #d8011e;
	}

	#menu {
		height: 41px;
	}

	#menu div.strokes {
		padding: 12px 10px;
		transition: padding 0.3s ease-in-out;
	}

	#menu div.strokes > div:nth-child(2) {
		margin: 4px 0;
	}

	#menu div.strokes.open {
		padding: 12px 5px 12px 14px;
	}

	#menu div.strokes.open > div:first-child {
		transform: rotateZ(45deg);
	}

	#menu div.strokes.open > div:last-child {
		transform: rotateZ(-45deg);
	}

	#menu div.icon {
		width: 41px;
	}

	#menu div.icon.selected::after {
		left: 17px;
	}

	#menu div.icon svg {
		margin: 9px 0 0 9px;
		width: 24px;
		height: 24px;
	}

	#menu div.menu {
		top: 41px;
	}
}

#slider {
	margin-left: 28px;
	position: relative;
	border-radius: 25px;
	width: 242px;
	height: 50px;
	padding: 2px;
	background-color: #d8011e;
	overflow: hidden;
	margin-top: 4px;
}

#slider.disabled {
	opacity: 0.5;
}

#slider div.callInfo {
	position: absolute;
	left: 25px;
	top: 0;
	right: 0;
	bottom: 0;
	font-size: 1.2em;
	text-align: center;
	line-height: 46px;
	font-weight: 600;
	color: white;
}

#slider div.callInfo svg {
	width: 1em;
	vertical-align: middle;
}

#slider div.handle {
	width: 46px;
	height: 46px;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 1);
	cursor: pointer;
	transition: all 0.25s ease-in;
}

#slider div.handle .color {
	fill: #d8011e;
}

#slider div.handle.dragging {
	transition: none;
	background-color: rgba(255, 255, 255, 0.9);
}

div.yourLocation {
	padding-left: 28px;
	margin-bottom: 1rem;
}

#root .ReactPasswordStrength {
	border: 1px solid #aaa;
	background-color: white;
	font-size: inherit;
	color: inherit;
	font-family: inherit;
	line-height: inherit;
	border-radius: 5px;
	overflow: hidden;
}

#root .ReactPasswordStrength-input {
	font-size: inherit;
	padding: 0.5em;
}

#root .ReactPasswordStrength-strength-bar {
	top: 0;
}

#root .ReactPasswordStrength-strength-desc {
	font-style: normal;
	padding: 0.5em;
	white-space: nowrap;
	width: auto;
}

label {
	display: block;
	margin-bottom: 1em;
}

label.noSpace {
	margin-bottom: 0;
}

label > span {
	display: block;
	font-size: 0.8em;
	font-weight: 600;
}

div.suggestionField {
	position: relative;
}

div.suggestionField ul {
	list-style: none;
	position: absolute;
	border: 1px solid #aaa;
	left: 0;
	top: 100%;
	width: 100%;
	background-color: white;
	margin-top: -5px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

div.suggestionField li {
	padding: 0 1em;
	line-height: 2em;
}

div.suggestionField li.selected,
div.suggestionField li:hover {
	background-color: #d8011e;
	color: white;
	cursor: pointer;
	font-weight: 600;
}

div.view {
	padding: 1rem;
}

div.view label > span {
	font-size: 0.8em;
	font-weight: 600;
}

div.back {
	margin-bottom: 2em;
}

div.back a {
	display: flex;
	align-items: center;
}

div.back svg {
	fill: #d8011e;
	height: 1em;
	width: 1em;
}

div.panel,
div.navPanel {
	margin-bottom: 2rem;
	border-radius: 5px;
	overflow: hidden;
}

div.panel {
	background-color: #efefef;
}

div.navPanel {
	border: 1px solid #535557;
}

div.navPanel h4,
div.panel h4 {
	background-color: #535557;
	padding: 0.5rem 1rem;
	margin: 0;
	color: white;
}

div.navPanel li {
	padding: 1rem 3rem 1rem 1rem;
	position: relative;
	cursor: pointer;
	border-bottom: 1px solid #ccc;
}

div.navPanel li:last-child {
	border-bottom: none;
}

div.navPanel li:hover {
	background-color: #efefef;
}

div.navPanel li::after {
	content: "";
	position: absolute;
	right: 1rem;
	top: 1.1rem;
	width: 1rem;
	height: 1rem;
	background-image: url("./assets/images/chevron_right.svg");
	background-repeat: no-repeat;
}

div.navPanel > div.buttonRow,
div.panel > div.buttonRow {
	margin-top: 0;
	border-top: 1px solid #ccc;
	padding: 1rem;
}

div.panel > div.error {
	margin-bottom: 0;
	border-radius: 0;
}

div.panel > form {
	padding: 1rem;
}

div.navPanel > i {
	display: block;
	padding: 1rem;
}

div.note {
	margin: 1rem;
	padding: 0.5rem;
	background-color: yellow;
	border-radius: 5px;
}

label.checkbox {
	position: relative;
	padding-left: 28px;
	cursor: pointer;
}

label.checkbox input {
	display: none;
}

label.checkbox svg {
	position: absolute;
	left: 0;
	top: 2px;
	width: 20px;
}

label.checkbox svg .box {
	stroke: #d8011e;
	fill: none;
}

label.checkbox svg .mark {
	display: none;
	fill: #fff;
}

label.checkbox input:checked ~ svg .box {
	fill: #d8011e;
	stroke: #d8011e;
}

label.checkbox input:checked ~ svg .mark {
	display: block;
}

.pageSlider-enter.left {
	transform: translate3d(-100%, 0, 0);
}

.pageSlider-enter.right {
	transform: translate3d(100%, 0, 0);
}

.pageSlider-enter-done {
	transform: translate3d(0, 0, 0);
}

.pageSlider-enter-active.right,
.pageSlider-enter-active.left {
	transform: translate3d(0, 0, 0);
	transition: all 2000ms ease-in-out;
	z-index: 2;
}

.pageSlider-exit {
	transform: translate3d(0, 0, 0);
}

.pageSlider-exit-active.left {
	transform: translate3d(100%, 0, 0);
	transition: all 2000ms ease-in-out;
	z-index: 1;
}

.pageSlider-exit-active.right {
	transform: translate3d(-100%, 0, 0);
	transition: all 2000ms ease-in-out;
	z-index: 1;
}

div.text ul {
	list-style-type: square;
	list-style-position: outside;
	padding-left: 2em;
	margin-bottom: 1em;
}

div.text ul ul {
	list-style-type: circle;
	margin-top: 0.5em;
}

div.text li {
	padding: 0.25em 0;
}

div.text li:first-child {
	padding-top: 0;
}

div.text p {
	margin-bottom: 1em;
}

div.text h4 {
	margin-top: 2em;
}

div.text h1 + h4 {
	margin-top: 0;
}
